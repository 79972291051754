.media {
    transition: .5s opacity var(--ease);
    position: relative;
    overflow: hidden;
    padding: percentage(10/16) 0 0;

    &.loading {
        opacity: 0;
    }

    picture {
        position: absolute;
        top: 0;
        left: 0;
        width: percentage(1);
        height: percentage(1);
    }

    img {
        display: block;
        width: percentage(1);
        height: percentage(1);
        object-fit: cover;
    }
}
