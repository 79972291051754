/**
 * Scrollbar stylesheet
 */

@media (--lg) {
    ::-webkit-scrollbar-track {
        background: #ccc;
        border: 0 none transparent;
        border-radius: 0;
        box-shadow: none;
    }

    ::-webkit-scrollbar-thumb {
        background: #999;
        border: 0 none transparent;
        border-radius: 0;
        box-shadow: none;
    }

    ::-webkit-scrollbar-button {
        width: 0;
        height: 0;
    }

    ::-webkit-scrollbar-corner {
        background: transparent;
    }

    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
}
