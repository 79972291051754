.hero {
    // overflow: hidden;
    padding: 0 0 130px;
    // position: relative;

    @media (--md) {
        padding-bottom: 160px;
    }

    @media (--lg) {
        padding-bottom: 190px;
    }

    @media (--xl) {
        padding-bottom: 210px;
    }

    .media {
        padding: 0;
        width: percentage(1);
        height: percentage(1);
        background: none;
        transition: none;

        picture {
            position: initial;
        }

        img {
            object-fit: fill;
        }
    }

    .container {
        position: relative;
        min-height: 500px;
        max-height: 640px;
        height: 80vh;

        @media (--md) {
            min-height: 600px;
            max-height: 800px;
        }

        @media (--lg) {
            min-height: 50vw;
            max-height: 900px;
            height: calc(100vh - 100px);
            // margin-top: 100px;
        }
    }

    .hero-media {
        width: percentage(1);
        height: percentage(1);
    }

    &-loader {
        width: 30px;
        height: 30px;
        position: absolute;
        left: percentage(.5);
        bottom: 30vh;
        margin: -15px 0 0 -15px;

        figure {
            animation: 1s linear .5s rotate infinite forwards;
        }
    }

    &-item {
        position: absolute;

        img {
            width: auto;
        }
    }

    &-main {
        top: 0;
        left: percentage(.5);
        transform: translate3d(#{percentage(-.4)}, 0, 0);
        height: percentage(1);
        display: block;

        @media (--sm) {
            transform: translate3d(#{percentage(-.5)}, 0, 0);
        }

        @media (--lg) {
            transform: translate3d(#{percentage(-.5)}, 100px, 0);
        }
    }

    &-2 {
        top: percentage(.9);
        left: 0;
        height: percentage(.4);

        @media (--lg) {
            left: percentage(.02);
        }
    }

    &-3 {
        top: percentage(.9);
        left: percentage(.4);
        height: percentage(.45);
    }

    &-4 {
        visibility: hidden;
        top: percentage(.75);
        left: percentage(.68);
        height: percentage(.35);

        @media (--md) {
            visibility: visible;
        }
    }
}
