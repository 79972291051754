* {
    @include reset;
}

::selection {
    background-color: col(blue);
    color: white;
}

html,
body {
    width: percentage(1);
    height: percentage(1);
    -webkit-tap-highlight-color: transparent;
}

html {
    font-size: #{$baseFontSize}px;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: var(--font-default);
    color: var(--color-default, black);
    width: 100vw;
    overflow-x: hidden;
    overflow-y: scroll;

    &.menu-visible {
        overflow: hidden;

        @media (--lg) {
            overflow-y: scroll;
        }
    }

    &.zoom-visible {
        overflow: hidden;
    }
}

h2 {
    @include title-2;
}

h3 {
    @include title-3;
}

p,
li {
    @include text-paragraph;
}

sub {
    font-size: .5em;
}

.container {
    width: var(--container-width, #{percentage(1)});
    max-width: var(--container);
    padding-left: var(--gutter);
    padding-right: var(--gutter);
    margin: 0 auto;
}

.main-content {
    padding: 0 0 spacer(10);
    overflow: hidden;
    transition: .5s opacity var(--ease);

    &.page-text {
        @media (--lg) {
            padding-top: spacer(10);
        }

        .content-section:first-child {
            padding-top: 0;
        }
    }

    .menu-visible & {
        opacity: .5;
    }
}

.app-content,
.main-footer {
    display: none;
}

#orejime {
    transition: .5s all var(--ease);
    opacity: 0;
    visibility: hidden;
}
