.main-navigation,
.main-navigation-footer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: percentage(1);
    font-family: var(--font-saritha);
    z-index: 100;
    background: white;
    transform: translate3d(0, #{percentage(-1)}, 0);
    transition: .5s transform .2s var(--ease);

    @media (--lg) {
        background: none;
        transform: none;
        height: auto;
    }

    ul {
        height: percentage(1);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 8vh percentage(2/12);
        transform: translate3d(0, 20px, 0);
        transition: 1s all var(--ease);
        opacity: 0;

        @media (--lg) {
            padding: spacer(8) var(--gutter);
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: percentage(9/12);
            margin-left: percentage(3/12);
            transform: none;
            opacity: 1;
        }

        .menu-visible & {
            transition-delay: .3s;
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }

    a {
        display: inline-block;
        transition: .25s all var(--ease);

        &:hover {
            color: col(blue-light);
        }
    }

    li {
        padding: 0 .5em;
        font-size: prem(30);
        opacity: 0;

        @media (--lg) {
            font-size: 1.9vw;
            text-align: center;
        }

        @media (--xl) {
            font-size: prem(26);
        }

        a {
            &:hover {
                transform: translate3d(0, 5px, 0);
            }
        }
    }

    .background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .media {
            padding: 0;
            height: percentage(1);

            @media (--lg) {
                display: none;
            }
        }

        &-overlay {
            position: absolute;
            background: linear-gradient(to bottom, #fff 30%, rgba(#fff, 0) 60%);
            top: 0;
            left: 0;
            right: 0;
            height: 200px;
            visibility: hidden;
            pointer-events: none;

            @media (--lg) {
                visibility: visible;
                opacity: 0;
            }
        }
    }

    .container {
        position: relative;
        height: percentage(1);
        padding: 0;
    }

    .main-brand {
        display: none;
        position: absolute;
        z-index: 101;
        top: 20px;
        left: percentage(1.5/12);
        margin: 0 0 0 -75px;
        opacity: 0;

        @media (--lg) {
            display: block;
        }

        svg {
            width: 130px;
        }
    }

    .menu-visible & {
        transition-delay: 0s;
        transform: translate3d(0, 0, 0);
    }
}

.main-header {
    .toggle-menu {
        position: fixed;
        width: 32px;
        height: 28px;
        left: calc(#{percentage(1/12)} - 16px);
        top: spacer(5);
        z-index: 101;

        @media (--lg) {
            display: none;
        }

        &::before,
        &::after {
            content: '';
        }

        &::before,
        &::after,
        span {
            display: block;
            height: 3px;
            width: 32px;
            background: black;
            border-radius: 10px;
            position: absolute;
            left: 0;
        }

        &::before {
            top: 0;
        }

        span {
            top: 12px;
        }

        &::after {
            top: 24px;
        }
    }
}
